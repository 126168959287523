import * as Dialog from "@radix-ui/react-dialog";
import CloseIcon from "../icons/close";
import { MainPurpleButton } from "../buttons/MainButtons";
import React from "react";

export type PopupContentProps = {
  title: string;
  children: any;
  buttonLabel: string;
  buttonBackground?: string;
  onSubmit: () => void;
};

// 팝업상자 틀
export const PopupContent = React.forwardRef(
  (
    {
      title,
      children,
      buttonLabel,
      buttonBackground,
      onSubmit,
    }: PopupContentProps,
    ref
  ) => {
    return (
      <Dialog.Content
        style={{ zIndex: 70 }}
        className="fixed left-[50%] top-[50%] flex w-fit translate-x-[-50%] translate-y-[-50%] flex-col rounded rounded-lg bg-white focus:outline-none"
      >
        <Dialog.Title className="flex h-[49px] w-full items-center justify-center border border-x-0 border-t-0 border-b-[#D8DAE4] text-base font-semibold text-ffq_navy_01">
          {title}
          <Dialog.Close asChild>
            <button className="absolute right-[17px] top-[17px] focus:outline-none">
              <CloseIcon />
            </button>
          </Dialog.Close>
        </Dialog.Title>
        {children}
        <footer className="flex h-[72px] w-full items-center justify-center rounded-b-lg border border-x-0 border-b-0 border-t-[#D8DAE4] bg-white">
          <MainPurpleButton
            backGround={buttonBackground}
            onClick={onSubmit}
            label={buttonLabel}
            name="popupSubmit"
          />
        </footer>
      </Dialog.Content>
    );
  }
);
PopupContent.displayName = "PopupContent";
