import { useEffect, useRef, useState } from "react";

export type PopupInputProps = {
  placeholder?: string;
  onInputChange: (inputValue: string) => void;
  isDisabled?: boolean;
  inputTitle?: string;
  defaultValue?: string;
  style?: React.CSSProperties;
  shouldClear?: boolean;
  onClick?: () => void;
  keyDownFunction?: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const PopupInput = ({
  placeholder = "Search",
  onInputChange,
  isDisabled = false,
  inputTitle = "",
  style = { display: "flex" },
  defaultValue = "",
  onClick = () => {},
  shouldClear = false,
  keyDownFunction,
}: PopupInputProps) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const handleInputValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(e.target.value);
    onInputChange(e.target.value);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  // const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
  //   const adminText = e.currentTarget;

  //   //엔터키를 누를때마다 줄바꿈 되도록 하는 코드
  //   if (adminText && e.key === "Enter") {
  //     adminText.value = `${adminText.value}\n`;
  //   }

  //   //줄바꿈 시 자동으로 textarea height도 하단으로 내려가도록 하는 코드
  //   if (adminText && adminText.scrollHeight > adminText.clientHeight) {
  //     // textarea height 확장
  //     adminText.style.height = `${adminText.scrollHeight}px`;
  //   } else if (adminText && adminText.scrollHeight <= adminText.clientHeight) {
  //     // textarea height 축소
  //     adminText.style.height = `${adminText.scrollHeight - 21}px`;
  //   }
  // };

  useEffect(() => {
    if (shouldClear) {
      setInputValue(""); // clear input value when clearInput is true
    }
  }, [shouldClear]);
  return (
    <div className="flex">
      {inputTitle && (
        <div className="mr-4 flex shrink-0 items-center text-xs text-[#6D7081]">
          {inputTitle}
        </div>
      )}
      <textarea
        onClick={onClick}
        style={{
          flexDirection: "column",
          resize: "none",
          textAlign: "start",
          paddingTop: "7px",
          cursor: isDisabled ? "pointer" : "auto",
          ...style,
          //  "&::-webkit-scrollbar": {
          //   width: "4px", // 스크롤바의 너비
          //   paddingRight: "5px",
          //   marginRight: "5px",
          // },
          // "&::-webkit-scrollbar-track": {
          //   background: "transparent", // 스크롤바의 추적 영역 배경색
          // },
          // "&::-webkit-scrollbar-thumb": {
          //   marginRight: "3px",
          //   background: "#B9BDD2", // 스크롤바의 썸네일 색상
          //   borderRadius: "5px", // 스크롤바의 썸네일 반지름
          // },
        }}
        className={`flex h-[32px] items-center rounded border border-[#B9BDD2] bg-[#FFFFFF] px-[9px] text-xs text-[#23242D] focus:outline-none`}
        placeholder={placeholder}
        value={inputValue ? inputValue : ""}
        onKeyDown={keyDownFunction}
        onChange={handleInputValue}
        readOnly={isDisabled}
      />
    </div>
  );
};

export const ExpandingWidthInput = ({
  placeholder = "Search",
  onInputChange,
  isDisabled = false,
  inputTitle = "",
  defaultValue = "",
  shouldClear = false,
}: PopupInputProps) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue);

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onInputChange(e.target.value);
  };

  useEffect(() => {
    if (shouldClear) {
      setInputValue(""); // clear input value when clearInput is true
    }
  }, [shouldClear]);
  return (
    <div className="flex">
      {inputTitle && (
        <div className="mr-4 flex shrink-0 items-center text-xs text-[#6D7081]">
          {inputTitle}
        </div>
      )}
      <input
        className={`flex h-[32px] items-center rounded border border-[#B9BDD2] bg-[#FFFFFF] px-[9px] text-xs text-[#23242D] focus:outline-none`}
        placeholder={placeholder}
        value={defaultValue}
        onChange={handleInputValue}
        disabled={isDisabled}
      />
    </div>
  );
};
