export type ButtonSize =
  | "small"
  | "medium"
  | "large"
  | "extraLarge"
  | "flexible"
  | "notice"
  | "widthMedium"
  | "refund";

export const getSizeClassName = (size: ButtonSize): string => {
  // decide class depending on size using switch statement
  let sizeClassName = "";
  switch (size) {
    case "flexible":
      sizeClassName = "px-[26px] py-3 h-[38px]";
      break;
    case "small":
      sizeClassName = "w-[77px] h-[38px]";
      break;
    case "medium":
      sizeClassName = "w-[100px] h-11";
      break;
    case "large":
      sizeClassName = "w-80 h-11";
      break;
    case "extraLarge":
      sizeClassName = "h-[38px] w-[120px]";
      break;
    case "flexible":
      sizeClassName = "py-[11px] px-[25px]";
      break;
    case "widthMedium":
      sizeClassName = "w-[90px] h-[39px]";
      break;
    case "notice":
      sizeClassName = "w-[77px] h-[39px]";
      break;
    case "refund":
      sizeClassName = "h-[38px] w-[110px]";
      break;
    default:
      // 이렇게 하면 check하지 않은 type 이 있으면 lint 에러가 발생합니다.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _typecheck: string = size;
      throw new Error(`Unexpected size : ${_typecheck}`);
  }
  return sizeClassName;
};
