import { getSizeClassName } from "./button";
import type { ButtonSize } from "./button";

export type MainButtonProps = {
  onClick: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  label: string;
  size?: ButtonSize;
  disabled?: boolean;
  value?: string;
  name?: string;
  backGround?: string;
};
export const MainPurpleButton = ({
  onClick,
  type,
  label,
  disabled = false,
  size = "small",
  value = "empty",
  name = "_action",
  backGround = "#826AFF",
}: MainButtonProps) => {
  const sizeClassName = getSizeClassName(size);

  return (
    <button
      type={type}
      value={value}
      disabled={disabled}
      onClick={onClick}
      name={name}
      style={{ background: backGround, opacity: disabled ? 0.5 : 1 }}
      className={`${sizeClassName} flex flex-row items-center justify-center rounded text-sm font-medium text-white `}
    >
      {label}
    </button>
  );
};

export const MainGrayButton = ({
  onClick,
  type,
  label,
  size = "small",
}: MainButtonProps) => {
  const sizeClassName = getSizeClassName(size);

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${sizeClassName} flex flex-row items-center justify-center rounded bg-[#A8A9C0] text-sm font-medium text-white `}
    >
      {label}
    </button>
  );
};
